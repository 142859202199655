import React, { useContext, useEffect } from "react"

import LayoutContext from "../../components/LayoutContext"
import HelmetHead from "../../components/HelmetHead"
import DE from "../../svgs/flags-iso/DE.svg"
import AT from "../../svgs/flags-iso/AT.svg"
import CH from "../../svgs/flags-iso/CH.svg"
import BetterWithRealtor from "../../components/generator/BetterWithRealtor"
import smoothScroll from "../../components/smoothScroll"
import { ArrowDown } from "react-feather"
import StepImgNo from "../../components/StepImgNo"
import { Link } from "gatsby"
// import Tooltip from "react-bootstrap/Tooltip"
// import OverlayTrigger from "react-bootstrap/OverlayTrigger"

const BesserMitMakler = () => {
  const [layout, setLayout] = useContext(LayoutContext)

  const canonicalPathname = `/de/besser-mit-makler/`

  useEffect(() => {
    setLayout(state => ({
      ...state,
      hide: true, // todo enabled cookie bar (but not in iframe)
    }))

    return function unmount() {
      setLayout(state => ({
        ...state,
        hide: false,
      }))
    }
  }, [])

  return (
    <>
      <HelmetHead
        title="Besser verkaufen mit Makler"
        description="Suchen Sie einen professionellen Makler? Bestens geeignet zu Ihrer Immobilie? Wir empfehlen Ihnen den passenden Makler in Ihrer Stadt."
        keywords="makler"
        links={[
          {
            rel: "canonical",
            href: `${process.env.GATSBY_SITE_URL}${canonicalPathname}`,
          },
        ]}
      />
      <section
        className="section-border border-primary d-flex align-items-center justify-content-center"
        style={{ minHeight: "80vh" }}
      >
        <div className="container-fluid" style={{ maxWidth: "767px" }}>
          <div
            className="card shadow-light-lg mx-md-auto"
            style={{ minHeight: "495px" }}
          >
            <BetterWithRealtor />
          </div>
        </div>
      </section>

      <section className="py-7 py-md-9 border-bottom">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <div className="scroll-beacon" id="besser-verkaufen-intro" />
              {/*<OverlayTrigger*/}
              {/*  placement="top"*/}
              {/*  overlay={*/}
              {/*    <Tooltip id="besser-verkaufer-scroll-tooltip">*/}
              {/*      <h3 className="fw-bold mb-1">*/}
              {/*        Mit Sicherheit erfolgreich verkaufen*/}
              {/*      </h3>*/}
              {/*      <h4 className="text-muted mb-0">*/}
              {/*        in 3 einfachen Schritten*/}
              {/*      </h4>*/}
              {/*    </Tooltip>*/}
              {/*  }*/}
              {/*>*/}
              <a
                href="#besser-verkaufen-intro"
                className="btn btn-white btn-rounded-circle shadow mt-n11 mt-md-n13"
                onClick={smoothScroll}
              >
                <ArrowDown size={20} />
              </a>
              {/*</OverlayTrigger>*/}
            </div>
          </div>

          <div className="scroll-beacon" id="so-funktionierts" />
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-8 text-center">
              <h2 className="fw-bold">Mit Sicherheit erfolgreich verkaufen</h2>
              <h3 className="text-muted mb-9 mb-md-11">
                in 3 einfachen Schritten
              </h3>
            </div>
          </div>
          <div className="row g-0 ">
            <div className="col-12 col-md-4">
              <div className="row mb-5 justify-content-center">
                <div className="col d-none d-lg-block" />
                <div className="col-auto mt-8 mt-md-0">
                  <StepImgNo
                    src="ImmobilieGartenCenter.jpg"
                    alt="Haus - Daten Immobilie"
                    no={1}
                    dataSal="slide-down"
                    dataSalDelay="200"
                    dataSalDuration="700"
                  />
                </div>
                <div className="col d-none d-lg-block">
                  <hr className="border-primary-desat" />
                </div>
              </div>
              <h3 className="fw-bold px-2">Sie profitieren & können helfen</h3>
              <p className="text-muted mb-6 mb-md-0 px-2">
                Im Durchschnitt verkauft jeder Deutsche in seinem Leben 1,2
                Immobilien. Wenn man die ersten Erfahrungen gemacht hat, braucht
                man sie meist schon garnicht mehr. Profitieren Sie von den
                Erfahrungen anderer! Wir evaluieren echte Bewertungen von
                Verkäufern. Gleich­zeitig können Sie mit Ihrer Erfahrung Ihren
                Beitrag leisten und anderen Nutzern helfen: so entsteht ein
                kollektiver Erfahrungs­schatz und eine wertvolle
                Win-Win-Situation.
              </p>
            </div>
            <div className="col-12 col-md-4">
              <div className="row mb-5 justify-content-center">
                <div className="col d-none d-lg-block">
                  <hr className="border-primary-desat" />
                </div>
                <div className="col-auto mt-8 mt-md-0">
                  <StepImgNo
                    src="Fotolia_61667417_S.jpg"
                    alt="Berechnung Immobilienwert"
                    no={2}
                    dataSal="slide-down"
                    dataSalDelay="500"
                    dataSalDuration="700"
                  />
                </div>
                <div className="col d-none d-lg-block">
                  <hr className="border-primary-desat" />
                </div>
              </div>
              <h3 className="fw-bold px-2">Wir lassen uns am Erfolg messen</h3>
              <p className="text-muted mb-6 mb-md-0 px-2">
                Sie profitieren von vertraulichen Informationen, die wir über
                die Makler in Ihrer Region sammeln und auswerten. Wir prüfen die
                Qualität der Exposés, Web­auftritte, Leistungs­angebote, den
                Umgang mit Kauf­interessenten und suchen das persönliche
                Gespräch. Darüber hinaus analysieren wir über Jahre hinweg die
                Annoncen und die Jahres­abschlüsse und ermitteln so einen
                Qualitäts­faktor - auch im eigenen Interesse, da wir vom
                empfohlenen Makler am Erfolg beteiligt werden.
              </p>
            </div>
            <div className="col-12 col-md-4">
              <div className="row mb-5 justify-content-center">
                <div className="col d-none d-lg-block">
                  <hr className="border-primary-desat" />
                </div>
                <div className="col-auto mt-8 mt-md-0">
                  <StepImgNo
                    src="Energieausweis.jpg"
                    alt="Wertermittlung Immobilie - Preis"
                    no={3}
                    dataSal="slide-down"
                    dataSalDelay="800"
                    dataSalDuration="700"
                  />
                </div>
                <div className="col d-none d-lg-block" />
              </div>
              <h3 className="fw-bold px-2">Sie nutzen alle Vorteile</h3>
              <p className="text-muted mb-0 px-2">
                Sie nutzen alle Vorteile für den erfolgreichen Verkauf Ihrer
                Immobilie: kostenfrei und mit nur einem Klick. Legen Sie jetzt
                los.
              </p>
            </div>
          </div>

          <div className="d-flex my-5 justify-content-between">
            <div className="d-inline-block">
              <Link
                to="/de/immobilienpreise-deutschland/"
                className="me-2"
                aria-label="Immobilienpreise Deutschland"
              >
                <DE
                  height="18px"
                  width="18px"
                  viewBox="0 0 640 480"
                  className="rounded"
                />
              </Link>
              <Link
                to="/de/immobilienmakler-oesterreich/"
                className="me-2"
                aria-label="Immobilienmakler Österreich"
              >
                <AT
                  height="18px"
                  width="18px"
                  viewBox="0 0 640 480"
                  className="rounded"
                />
              </Link>
              <Link
                to="/de/immobilienmakler-schweiz/"
                className="me-5"
                aria-label="Immobilienmakler Schweiz"
              >
                <CH
                  height="18px"
                  width="18px"
                  viewBox="0 0 640 480"
                  className="rounded"
                />
              </Link>
            </div>
            <div className="d-inline-block" style={{ fontSize: "13px" }}>
              <Link to="/de/impressum/" className="text-gray-800">
                Impressum
              </Link>{" "}
              |{" "}
              <Link to="/de/datenschutz/" className="text-gray-800">
                Datenschutz
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default BesserMitMakler
