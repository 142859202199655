import React from "react"
import Image from "./Image"

const StepImgNo = ({
  src,
  alt,
  no,
  dataSal,
  dataSalDelay,
  dataSalDuration,
}) => (
  <div
    className="position-relative mt-n8"
    data-sal={dataSal}
    data-sal-delay={dataSalDelay}
    data-sal-duration={dataSalDuration}
  >
    <div
      className="rounded-circle text-white text-center bg-primary position-absolute"
      style={{
        top: "5px",
        left: "-5px",
        width: "45px",
        height: "45px",
        fontSize: "30px",
        zIndex: "1",
      }}
    >
      {no}
    </div>
    <Image
      className="img-fluid rounded-circle"
      src={src}
      style={{ height: "150px", width: "150px" }}
      alt={alt}
    />
  </div>
)

export default StepImgNo
